import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import history from './app/utils/history';
import Footer from "./app/components/Footer";
import TopBar from "./app/components/TopBar/TopBar";
import Routes from "./app/routes";
import store from "./app/store";
import ReactGA from "react-ga4";
import NavBar from './app/components/NavBar/index';
import { ContentTypesProvider } from './app/utils/content-types-traduction-context';

const App = (props) => {

    useEffect(() => {
        document.title = "Motor de B\u00fasqueda Tesauro - " + window.location.pathname
        ReactGA.set({ page: window.location.pathname });
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [])

    history.listen((location) => {

        document.title = "Motor de B\u00fasqueda Tesauro - " + location.pathname
        ReactGA.set({ page: location.pathname });
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    });

    console.log("App version: V_1_5_11_Prod")

    return (
        <>
            <Provider store={store}>
                <ContentTypesProvider>
                    <TopBar />
                    <NavBar/>
                    <div className="container_app">
                        <Routes />
                    </div>
                    <Footer />
                </ContentTypesProvider>
            </Provider>
        </>
    );
};

export default App;
